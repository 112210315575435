class MobileMenu{
    constructor(element){
        this.element = element;
        let menu_items = this.element.querySelectorAll('[data-clicker]');

        console.log(menu_items);

        this._showSubMenu = this._showSubMenu.bind(this);

        menu_items.forEach(menu_item => {
           menu_item.addEventListener('click', this._showSubMenu);
        });
    }

    _showSubMenu(e){
        e.preventDefault();
        let currentTarget = e.target;
        let submenu = currentTarget.parentNode.parentNode.querySelector('.mobile-navigation');
        submenu.classList.toggle('show');
        console.log(submenu);
    }
}

export default MobileMenu;