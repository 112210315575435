class HiddenFilterOptions {
    constructor(selector) {
        this.selector = selector;
        this.button = this.selector.querySelector('[data-element="hidden-options-button"]');
        this.container = this.selector.querySelector('[data-element="hidden-options-container"]');

        if (this.button !== null) {
            this.button.addEventListener('click', (e) => {
                e.preventDefault();

                if (!this.container.classList.contains('active')) {
                    this.container.style.display = 'block';
                    this.container.classList.add('active');
                    this.button.innerText = 'Pokaż mniej';
                } else {
                    this.container.style.display = 'none';
                    this.container.classList.remove('active');
                    this.button.innerText = 'Pokaż więcej';
                }
            });
        }
    }
}
export default HiddenFilterOptions;