class CategoryTab{
    constructor(element){
        this.element = element;
        this.tabs = this.element.querySelectorAll('[data-element="category-tab"]');
        this.buttons = this.element.querySelectorAll('[data-element="button"]');

        this._toggleTab = this._toggleTab.bind(this);

        this.tabs.forEach(tab => {
            let single_tab_options = tab.querySelectorAll('[data-element="option"]');
            for(let i = 0; i < single_tab_options.length; i++){
                if(i > 3){
                    single_tab_options[i].classList.add('hidden');
                }
            }
        });

        this.buttons.forEach(button => {
            button.addEventListener('click', this._toggleTab);
        });
    }

    _toggleTab(e){
        e.preventDefault();

        e.target.classList.toggle('shown-more');

        let clicked_container_options = e.target.parentNode.querySelectorAll('[data-element="option"]');

        if(e.target.classList.contains('shown-more')){
            e.target.innerText = 'Pokaż mniej';
            clicked_container_options.forEach(option => {
                option.classList.remove('hidden');
            });
        } else {
            e.target.innerText = 'Pokaż więcej';
            for(let i = 0; i < clicked_container_options.length; i++){
                if(i > 3){
                    clicked_container_options[i].classList.add('hidden');
                }
            }
        }


    }
}

export default CategoryTab;