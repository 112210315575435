class MobileFilterNavigation {
    constructor(selector) {
        this.selector = selector;
        this.filter_button = this.selector.querySelector('[data-element="button"]');
        this.filter_widget = document.querySelector('.js-filter-box');
        this.filter_close_button = document.createElement('a');

        this.filter_close_button.setAttribute('data-element', 'close');
        this.filter_close_button.setAttribute('href', '');
        this.filter_close_button.classList.add('box__button-close');
        this.filter_close_button.innerText = '×';

        this.filter_widget.append(this.filter_close_button);

        this.filter_button.addEventListener('click', (e) => {
           e.preventDefault();

           if (this.filter_button.classList.contains('shown')) {
               this.filter_button.classList.remove('shown');
               this.filter_widget.classList.remove('shown');

               document.querySelector('body').style.overflow = 'auto';
               document.querySelector('html').style.overflow = 'auto';
           } else {
               this.filter_button.classList.add('shown');
               this.filter_widget.classList.add('shown');

               document.querySelector('body').style.overflow = 'hidden';
               document.querySelector('html').style.overflow = 'hidden';
           }
        });

        this.filter_close_button.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.filter_widget.classList.contains('shown')) {
                this.filter_widget.classList.remove('shown');
                this.filter_button.classList.remove('shown');

                document.querySelector('body').style.overflow = 'auto';
                document.querySelector('html').style.overflow = 'auto';
            }
        });

        if (window.innerWidth <= 1000) {
            document.querySelector('body').style.paddingBottom = this.selector.offsetHeight + 'px';
        }
    }
}
export default MobileFilterNavigation;