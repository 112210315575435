class ContactModal {
    constructor(element){
        this.element = element;
        this.width = window.innerWidth;

        this.contactModalButton = document.querySelector('#contactModalButton');
        this.contactModal = document.querySelector('#contactModal');

        console.log(this.contactModal);

        if(this.contactModalButton !== null && this.contactModal !== null){
            this._addModalClass = this._addModalClass.bind(this);
            this._toggleModalClass = this._toggleModalClass.bind(this);
            this._removeModalClass = this._removeModalClass.bind(this);

            this.contactModalButton.addEventListener('click', this._toggleModalClass);

            let width = window.innerWidth;
            if(width != null){
                if(width >= 900){
                    this.contactModalButton.addEventListener('mouseover', this._addModalClass);
                    this.contactModal.addEventListener('mouseover', this._addModalClass);
                    this.contactModalButton.addEventListener('mouseleave', this._removeModalClass);
                    this.contactModal.addEventListener('mouseleave', this._removeModalClass);
                } else {
                    document.addEventListener('click', function(e){
                        if (this.contactModal !== undefined) {
                            console.log(this.contactModal);
                            if (!this.contactModal.contains(e.target)) {
                                if(this.contactModal.classList.contains('contact-modal--active') && e.target !== this.contactModalButton){
                                    this.contactModal.classList.remove('contact-modal--active');
                                }
                            }
                        }
                    });
                }
            }
        }
    }

    _addModalClass(){
        if (this.contactModal !== null) {
            this.contactModal.classList.add('contact-modal--active');
        }
    }

    _toggleModalClass(){
        if (this.contactModal !== null) {
            this.contactModal.classList.toggle('contact-modal--active');
        }
    }

    _removeModalClass(){
        if (this.contactModal !== null) {
            this.contactModal.classList.remove('contact-modal--active');
        }
    }
}

export default ContactModal;