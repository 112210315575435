class InspirationProduct{
    constructor(selector){
        this.selector = selector;
        this.loader = this.selector.querySelector('[data-element="loader"]');
        this.box_content = this.selector.querySelector('[data-element="content"]');
        let product_id = this.selector.getAttribute('data-product-id');
        this.selector.addEventListener('mouseover', function(e){
            let info_container = document.querySelector('[data-product-id="' + product_id + '"]');
            info_container.style.zIndex = '100';
        });
        this.selector.addEventListener('mouseout', function () {
            let info_containers = document.querySelectorAll('[data-product-id]');
            console.log(info_containers);
            info_containers.forEach(infoContainer => {
               infoContainer.style.zIndex = '0';
            });
        });
        if(product_id > 0){
            this._getProductData(product_id).then((data) => {
                if(this.box_content !== null){
                    this.loader.classList.remove('loader--shown');

                    let image = document.createElement('div');
                    image.innerHTML = data.thumbnail;
                    this.box_content.appendChild(image);

                    let title = document.createElement('h3');
                    title.classList.add('arrangement-box__title');
                    title.innerText = data.name;
                    this.box_content.appendChild(title);

                    let link = document.createElement('a');
                    link.classList.add('arrangement-box__link');
                    link.setAttribute('href', data.link);
                    this.box_content.appendChild(link);
                }
            });
        }
    }

    _getProductData(product_id) {
        let url = '/wp-json/camero/v1/get_product_data/' + product_id;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}

export default InspirationProduct;