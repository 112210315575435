import Glide from '@glidejs/glide';
import GLightbox from "glightbox";
import Form from './form';
import ContactModal from './contactModal';
import InspirationProduct from './inspirationProduct';
import FilterTab from './filterTab';
import CategoryTab from './categoryTab';
import Navigation from './navigation';
import MobileMenu from './mobileMenu';

import { initMap } from '@netivo/base-scripts';
import BoxControl from "./boxControl";
import MobileFilterNavigation from "./mobileFilterNavigation";
import HiddenFilterOptions from "./hiddenFilterOptions";

let lightbox = new GLightbox();
// import initMap from '@netivo/base-scripts/javascript/map';
/**
 * Created by Michał on 11.05.2017.
 */
function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}
function toggleBlogMenu(){
    let menu = document.querySelector('#blog-menu');
    let blog_mask = document.querySelector('#blog-mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-blog-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    blog_mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let contactModal = document.querySelectorAll('.js-contact-modal');
if(contactModal !== null){
    contactModal.forEach(item => {
        new ContactModal(item);
    })
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
        }
    });
}

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }

    let header = document.querySelector('.js-header');
    let body = document.querySelector('body');
    if (header !== null) {
        if(window.scrollY > 10) {
            header.classList.add('header--scrolled');
        } else {
            header.classList.remove('header--scrolled');
        }
    }

    if (header !== null) {
        let navigation_element = header.querySelector('.navigation').querySelector('.menu-item-has-children');
        if (navigation_element !== null) {
            navigation_element.addEventListener('mouseover', (e) => {
                header.classList.add('header--scrolled-plus');
            });
            navigation_element.addEventListener('mouseout', (e) => {
                header.classList.remove('header--scrolled-plus');
            });
        }
    }
});
window.addEventListener('DOMContentLoaded', () => {

    let lists = document.querySelectorAll('.js-checkbox-list');
    if(lists !== null){
        lists.forEach(list => {
           let checkboxes = list.querySelectorAll('[data-element="checkbox"]');
           if(checkboxes !== null){
               checkboxes.forEach(checkbox => {
                  checkbox.addEventListener('click', function(e){
                      let link = e.target.getAttribute('data-link');
                      console.log(link);
                      window.location.href = link;
                  });
               });
           }
        });
    }

    let ms = null;
    let main_slider = document.querySelector('.js-homepage-slider');
    if (main_slider !== null) {
        ms = new Glide(main_slider, {
            type: 'carousel',
            perView: 1,
            autoplay: 3500,
            animationDuration: 1,
            animationTimingFunc: 'ease-in-out',
            breakpoints: {
                400: {
                    autoplay: 4500,
                },
                600: {
                    autoplay: 3500,
                }
            }
        }).mount();
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        new Form(contact_form, {
            action: '/wp-admin/admin-ajax.php',
            data: element => {
                let data = {
                    first_name: '',
                    city: '',
                    email: '',
                    phone: '',
                    subject: '',
                    message: '',
                    client: '',
                    architect: '',
                    action: 'nt_send_contact_form'
                };
                if (element.querySelector('[name="first_name"]') != null) data.first_name = element.querySelector('[name="first_name"]').value;
                if (element.querySelector('[name="city"]') != null) data.city = element.querySelector('[name="city"]').value;
                if (element.querySelector('[name="email"]') != null) data.email = element.querySelector('[name="email"]').value;
                if (element.querySelector('[name="phone"]') != null) data.phone = element.querySelector('[name="phone"]').value;
                if (element.querySelector('[name="subject"]') != null) data.subject = element.querySelector('[name="subject"]').value;
                if (element.querySelector('[name="message"]') != null) data.message = element.querySelector('[name="message"]').value;
                return data;
            },
            beforeSubmit: (element, hashed) => {
                let loader = element.querySelector('.form__loader');
                loader.style.display = 'flex';
                loader.querySelector('.loader__load').style.display = 'block';
            },
            success: (element, response) => {
                if (response.status === 'success') {
                    let loader = element.querySelector('.form__loader');
                    let successMessage = loader.querySelector('.form__loader-success');
                    successMessage.innerHTML = 'Dziękujemy za wypełnienie formularza.';
                    loader.querySelector('.loader__load').style.display = 'none';
                    element.querySelector('[name="first_name"]').value = '';
                    element.querySelector('[name="city"]').value = '';
                    element.querySelector('[name="email"]').value = '';
                    element.querySelector('[name="phone"]').value = '';
                    element.querySelector('[name="subject"]').value = '';
                    element.querySelector('[name="message"]').value = '';
                    successMessage.style.display = 'block';
                    setTimeout(() => {
                        loader.style.display = 'none';
                        successMessage.style.display = 'none';
                        successMessage.innerHTML = '';
                    }, 8000);
                }
            },
            error: (element, response) => {
                let loader = element.querySelector('.form__loader');
                let errorMessage = loader.querySelector('.form__loader-error');
                errorMessage.innerHTML = 'Przepraszamy wystąpił problem. Odśwież stronę i spróbuj ponownie.';
                loader.querySelector('.loader__load').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => {
                    loader.style.display = 'none';
                    errorMessage.style.display = 'none';
                    errorMessage.innerHTML = '';
                }, 8000);
            }
        });
    }

    // let architect_contact_form = document.querySelector('.js-architect-contact-form');
    // console.log(architect_contact_form);

    let lightbox = GLightbox();

    let inspirationProducts = document.querySelectorAll('.js-inspiration-product');

    if(inspirationProducts !== null){
        inspirationProducts.forEach(element => {
           new InspirationProduct(element);
        });
    }

    let filters_box = document.querySelectorAll('.js-filter-box');
    if(filters_box !== null){
        filters_box.forEach(element => {
            new FilterTab(element);
        });
    }

    let categories_box = document.querySelectorAll('.js-categories-tabs');
    if(categories_box !== null){
        categories_box.forEach(element => {
           new CategoryTab(element);
        });
    }

    let navigation = document.querySelectorAll('.js-navigation');
    if(navigation !== null){
        navigation.forEach(element => {
           new Navigation(element);
        });
    }

    let mobile_navigation = document.querySelectorAll('.js-mobile-menu');
    if(mobile_navigation !== null){
        mobile_navigation.forEach(element => {
            new MobileMenu(element);
        })
    }

    let mobile_filters = document.querySelectorAll('.js-mobile-filter-navigation');
    if (mobile_filters !== null) {
        mobile_filters.forEach(item => {
            if (window.innerWidth <= 1000) {
                new MobileFilterNavigation(item);
            }
        });
    }

    let hidden_filters_options = document.querySelectorAll('.js-hidden-filter-options');
    if (hidden_filters_options !== null) {
        hidden_filters_options.forEach(item => {
           new HiddenFilterOptions(item);
        });
    }
    // let boxes = document.querySelectorAll('.js-box-control');
    // if (boxes !== null) {
    //     boxes.forEach(element => {
    //         if (window.innerWidth <= 1000) {
    //             new BoxControl(element);
    //         }
    //     });
    // }
});

window.initMap = initMap;