class BoxControl {
    constructor(selector) {
        this.selector = selector;
        this.clicker = this.selector.querySelector('[data-element="clicker"]');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.open = false;

        this.clicker.addEventListener('click', (e) => {
            e.preventDefault();

            if (this.open === false) {
                this.open = true;
                this._addActiveClass();
                this._changeLabel();
            } else {
                this.open = false;
                this._removeActiveClass();
                this._changeLabel();
            }
        })
    }
    _addActiveClass() {
        this.selector.classList.add('box--open');
    }
    _removeActiveClass() {
        if (this.selector.classList.contains('box--open')) {
            this.selector.classList.remove('box--open');
        }
    }
    _changeLabel() {
        this.clicker.innerText = ((this.open === true) ? '-' : '+');
    }

}
export default BoxControl;