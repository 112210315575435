class FilterTab{
    constructor(element){
        this.element = element;
        this.tab_labels = this.element.querySelectorAll('.tab__label-show-more-btn');
        this.tab_contents = this.element.querySelectorAll('.tab__content');
        this.tabs = this.element.querySelectorAll('.tab');

        this._toggleTab = this._toggleTab.bind(this);

        this.tab_contents.forEach(tab_content => {
           let tab_height = tab_content.offsetHeight;
           tab_content.setAttribute('data-height', tab_height);
           tab_content.style.height = 0;
        });

        this.tab_labels.forEach(tab_label => {
            tab_label.previousElementSibling.style.fontWeight = 400;
            tab_label.addEventListener('click', this._toggleTab);
        });

        this.tab_contents.forEach(tab => {
            if(tab.classList.contains('active')){
                tab.previousElementSibling.querySelector('a').style.fontWeight = 700;
                let active_tab_height = tab.getAttribute('data-height');
                tab.style.height = active_tab_height + "px";
            }
        });

    }

    _toggleTab(e){
        e.preventDefault();
        this.tab_contents.forEach(tab_content => {
            tab_content.style.height= 0;
        });
        this.tab_labels.forEach(tab_label => {
            tab_label.previousElementSibling.style.fontWeight = 400;
        });
        e.target.previousElementSibling.style.fontWeight = 700;
        e.target.style.transform = 'rotate(90deg)';
        let currect_click_content = e.target.parentNode.nextElementSibling;
        let currect_click_content_height = currect_click_content.getAttribute('data-height');
        console.log(currect_click_content_height);
        currect_click_content.style.height = currect_click_content_height + "px";

    }
}

export default FilterTab;