class Submenu {
    constructor(element) {
        this.element = element;
        this.mouseOver = false;
        this.submenu_elements = this.element.querySelectorAll('.navigation__element');
        this.first_element = this.submenu_elements[0];
        this.first_element.classList.add('f-e');
        this.first_element.classList.add('active');

        let snc = element.querySelector('.sub-navigation-container');
        if (snc !== null) {
            let h = snc.clientHeight;
            let fl = h / 45;
            let hs_elements = snc.querySelectorAll('.navigation--submenu-inside');
            console.log(h, fl, hs_elements);
            if (hs_elements.length > 0) {
                hs_elements.forEach(item => {
                    let l = item.querySelectorAll('li').length;
                    let w = Math.ceil(l/fl) * 300;
                    item.style.width = w + 'px';
                });
            }
            snc.style.display = 'none';
            snc.style.opacity = 'initial';
            snc.style.visibility = 'initial';
        }

        this.submenu_elements.forEach(item => {
            if (!item.classList.contains('f-e')) {
                item.addEventListener('mouseover', (e) => {
                    this.mouseOver = true;
                    this._checkStatus();
                });
                item.addEventListener('mouseout', (e) => {
                    this.mouseOver = false;
                    this._checkStatus();
                });
            }
        });
    }
    _checkStatus() {
        if (this.mouseOver === true) {
            if (this.submenu_elements[0].classList.contains('f-e')) {
                this.submenu_elements[0].classList.remove('f-e');
                this.submenu_elements[0].classList.remove('active');
            }
        } else {
            this.submenu_elements[0].classList.add('f-e');
            this.submenu_elements[0].classList.add('active');
        }
    }
}
export default Submenu;