import Submenu from "./submenu";

class Navigation{
    constructor(element){
        this.element = element;

        let submenus = this.element.querySelectorAll('.navigation__element--has-submenu');
        submenus.forEach(menu => {
            new Submenu(menu);
        });
    }
}

export default Navigation;